import { css } from '@emotion/react';

export const NotoSansFont = css`
	font-family: 'Noto Sans KR', sans-serif;
	font-weight: 500;
	letter-spacing: -0.005em;
`;

export const UrbaneFont = css`
	font-family: 'Urbane', sans-serif;
`;

export const RobotoFont = css`
	font-family: 'Roboto', sans-serif;
	letter-spacing: 0.003em;
`;
