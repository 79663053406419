import WhiteLogo from '../assets/images/logo_h1.svg';
import RedLogo from '../assets/images/logo_h1_red.svg';
import { BLACK, WHITE } from '../constants/colors';
import { HeaderStyleType } from '../data/header';

export const HeaderStyle = {
	[HeaderStyleType.Transperant]: {
		logo: WhiteLogo,
		textColor: WHITE,
		subMenuTextColor: WHITE,
		bgColor: 'none',
		subMenuBgColor: 'rgba(255, 255, 255, 0.7)',
	},
	[HeaderStyleType.Background]: {
		logo: RedLogo,
		textColor: BLACK,
		subMenuTextColor: BLACK,
		bgColor: `rgba(255, 255, 255, 0.5)`,
		subMenuBgColor: 'rgba(255, 255, 255, 0.7)',
	},
	[HeaderStyleType.White]: {
		logo: RedLogo,
		textColor: BLACK,
		subMenuTextColor: BLACK,
		bgColor: WHITE,
		subMenuBgColor: `rgba(255, 255, 255, 0.8)`,
	},
	[HeaderStyleType.Black]: {
		logo: WhiteLogo,
		textColor: WHITE,
		subMenuTextColor: WHITE,
		bgColor: BLACK,
		subMenuBgColor: `rgba(78, 82, 99, 0.9)`,
	},
};
