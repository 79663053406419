import { Global } from '@emotion/react';
import React, { ReactNode } from 'react';
import GlobalStyle from '../styles/GlobalStyles';
import DesktopHeader from './DesktopHeader';
import Footer from './Footer';
import MobileHeader from './MobileHeader';
import HeaderStyleProvider from './providers/HeaderStyleProvider';
import SideBarProvider from './providers/SideBarProvider';
import SEO from './Seo';

interface LayoutProps {
	title?: string;
	description?: string;
	image?: string;
	children: ReactNode;
	isNoticePage?: boolean;
}

const Layout = ({ title, description, image, children, isNoticePage = false }: LayoutProps) => {
	return (
		<HeaderStyleProvider>
			<SideBarProvider>
				<DesktopHeader />
				<MobileHeader isNoticePage={isNoticePage} />
				<SEO title={title} description={description} image={image} />
				<Global styles={GlobalStyle} />
				{children}
				<Footer />
			</SideBarProvider>
		</HeaderStyleProvider>
	);
};

export default Layout;
