import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { isDevelopment } from './common';

export const BASE_URL = isDevelopment ? 'https://qa-homepage-api.petdoc.co.kr' : 'https://homepage-api.petdoc.co.kr';

export const instance = axios.create({
	withCredentials: false,
});

export const HTTP = {
	get: <ResponseType>(url: string, options?: AxiosRequestConfig): Promise<AxiosResponse<ResponseType>> =>
		instance.get(url, options),
	post: <ParamType, ResponseType>(
		url: string,
		param?: ParamType,
		options?: AxiosRequestConfig,
	): Promise<AxiosResponse<ResponseType>> => instance.post(url, param, options),
	patch: <ParamType, ResponseType>(
		url: string,
		param: ParamType,
		options?: AxiosRequestConfig,
	): Promise<AxiosResponse<ResponseType>> => instance.patch(url, param, options),
	delete: <ResponseType>(url: string, options?: AxiosRequestConfig): Promise<AxiosResponse<ResponseType>> =>
		instance.delete(url, options),
	put: <ParamType, ResponseType>(
		url: string,
		param: ParamType,
		options?: AxiosRequestConfig,
	): Promise<AxiosResponse<ResponseType>> => instance.put(url, param, options),
};
