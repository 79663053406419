import { useLocation } from '@reach/router';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';
import { isDevelopment } from '../utils/common';

const SEO = ({
	title,
	description,
	image,
	article = false,
}: {
	title?: string;
	description?: string;
	image?: string;
	article?: boolean;
}) => {
	const { pathname } = useLocation();
	const { site } = useStaticQuery(query);
	const { defaultTitle, defaultDescription, siteUrl, defaultImage, twitterUsername } = site.siteMetadata;
	const seo = {
		title: title || defaultTitle,
		description: description || defaultDescription,
		image: `${siteUrl}${image || defaultImage}`,
		url: `${siteUrl}${pathname}`,
	};
	return (
		<Helmet
			title={seo.title}
			htmlAttributes={{
				lang: 'ko',
			}}
		>
			<meta name="description" content={seo.description} />
			<meta name="image" content={seo.image} />
			<meta name="naver-site-verification" content="ff1d3620acac91cc6b2d221a34667affedbae157" />
			{typeof window !== "undefined" && (window.location.hostname == 'https://www.petdoc-korea.com' ||  window.location.hostname == 'www.petdoc-korea.com') && <meta name="robots" content="noindex" />}
			{/*{isDevelopment && <meta name="robots" content="noindex" />}*/}
			{seo.url && <meta property="og:url" content={seo.url} />}
			{(article ? true : null) && <meta property="og:type" content="article" />}
			{seo.title && <meta property="og:title" content={seo.title} />}
			{seo.description && <meta property="og:description" content={seo.description} />}
			{seo.image && <meta property="og:image" content={seo.image} />}
			<meta name="twitter:card" content="summary_large_image" />
			{twitterUsername && <meta name="twitter:creator" content={twitterUsername} />}
			{seo.title && <meta name="twitter:title" content={seo.title} />}
			{seo.description && <meta name="twitter:description" content={seo.description} />}
			{seo.image && <meta name="twitter:image" content={seo.image} />}
			<script type="application/ld+json">
				{`
   				{
      			"@context": "https://schema.org",
      			"@type": "Person",
      			"url": "https://www.petdoc-korea.com",
      			"name": "펫닥",
						"sameAs": [
							"https://play.google.com/store/apps/details?id=kr.co.petdoc.petdoc",
							"https://apps.apple.com/kr/app/%ED%8E%AB%EB%8B%A5-%EB%82%B4-%EC%86%90%EC%95%88%EC%97%90-%EC%88%98%EC%9D%98%EC%82%AC-%EC%86%8C%ED%99%98/id1059558618",
							"https://www.instagram.com/petdoc_official/",
							"https://www.facebook.com/Petdoc-%ED%8E%AB%EB%8B%A5-109105828255735",
							"https://www.youtube.com/channel/UCDJ5mikV_sfAvD8n_ZFiTvQ"
						]
    			}
  			`}
			</script>
			<script type="application/ld+json">
				{`
   				{
      			"@context": "https://schema.org",
      			"@type": "Person",
      			"url": "https://petdoc.co.kr",
      			"name": "펫닥",
						"sameAs": [
							"https://play.google.com/store/apps/details?id=kr.co.petdoc.petdoc",
							"https://apps.apple.com/kr/app/%ED%8E%AB%EB%8B%A5-%EB%82%B4-%EC%86%90%EC%95%88%EC%97%90-%EC%88%98%EC%9D%98%EC%82%AC-%EC%86%8C%ED%99%98/id1059558618",
							"https://www.instagram.com/petdoc_official/",
							"https://www.facebook.com/Petdoc-%ED%8E%AB%EB%8B%A5-109105828255735",
							"https://www.youtube.com/channel/UCDJ5mikV_sfAvD8n_ZFiTvQ"
						]
    			}
  			`}
			</script>
		</Helmet>
	);
};

export default SEO;

const query = graphql`
	query SEO {
		site {
			siteMetadata {
				defaultTitle: title
				defaultDescription: description
				siteUrl: url
				defaultImage: image
			}
		}
	}
`;
