import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import React, { useMemo } from 'react';
import { RED } from '../constants/colors';
import sideBarIcon from '../assets/icons/icon_sidebar.svg';
import { MOBILE_HEADER_HEIGHT } from '../constants/layout';
import { INTERNAL_URLS } from '../constants/urls';
import { ZIndex } from '../constants/zIndex';
import { headerData, HeaderMenuType, HeaderStyleType } from '../data/header';
import { RobotoFont } from '../styles/common';
import { HeaderStyle } from '../styles/header';
import { showOnMobileStyle } from '../styles/layout';
import { pxToRem } from '../utils/common';
import { useGetHeaderStyle } from './providers/HeaderStyleProvider';
import { useSetSideBarState } from './providers/SideBarProvider';

const MobileHeader = ({ isNoticePage }: { isNoticePage: boolean }) => {
	const { activeMenuType } = useGetHeaderStyle();
	const headerStyle = useMemo(() => HeaderStyle[HeaderStyleType.White], []);
	const { logo, textColor, bgColor } = headerStyle;

	// sideBar open 여부 제어
	const setIsSideBarOpen = useSetSideBarState();
	const handleSideBarButton = () => {
		setIsSideBarOpen((prev: boolean) => !prev);
	};

	return (
		<HeaderWrap bgColor={bgColor}>
			<HeaderSection>
				<HeaderMenuSection>
					<h1>
						<Link to={INTERNAL_URLS.HOME}>
							<HeaderLogo src={logo} alt="Petdoc" />
						</Link>
					</h1>
					<SideBarButton src={sideBarIcon} onClick={handleSideBarButton} alt="sidebar button" />
				</HeaderMenuSection>
				<nav>
					<HeaderNav>
						{headerData.map((data) => (
							<HeaderNavItems key={data.id}>
								{data.isOuter ? (
									<StyledALink href={data.link} target="_blank" rel="noopener noreferrer" color={textColor}>
										{data.text}
									</StyledALink>
								) : (
									<LinkWrapper
										isActive={
											activeMenuType === HeaderMenuType.Default && !isNoticePage
												? data.type === HeaderMenuType.Story
												: activeMenuType === data.type
										}
										color={textColor}
									>
										<Link to={data.link}>{data.text}</Link>
									</LinkWrapper>
								)}
							</HeaderNavItems>
						))}
					</HeaderNav>
				</nav>
			</HeaderSection>
		</HeaderWrap>
	);
};

export default MobileHeader;

const HeaderWrap = styled.header(
	({ bgColor }: { bgColor: string }) => css`
		${showOnMobileStyle};
		position: sticky;
		top: 0;
		width: 100%;
		z-index: ${ZIndex.Header};
		background-color: ${bgColor};
		height: ${pxToRem(MOBILE_HEADER_HEIGHT)};
		border-bottom: 1px solid rgba(216, 216, 216, 0.5);
	`,
);

const HeaderSection = styled.div`
	padding: 0 ${pxToRem(24)};
`;

const HeaderMenuSection = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const HeaderLogo = styled.img`
	width: ${pxToRem(110.5)};
	height: auto;
	padding-top: ${pxToRem(13)};
	padding-bottom: ${pxToRem(15)};
`;

const SideBarButton = styled.img`
	cursor: pointer;
`;

const HeaderNav = styled.ul`
	display: flex;
	justify-content: space-between;
	padding-bottom: ${pxToRem(12)};
	font-size: ${pxToRem(15)};
`;

const HeaderNavItems = styled.li``;

const LinkStyle = css`
	${RobotoFont};
`;

const StyledALink = styled.a(
	({ color }: { color: string }) => css`
		${LinkStyle}
		color: ${color};
	`,
);

const LinkWrapper = styled.p(
	({ isActive, color }: { isActive?: boolean; color: string }) => css`
		a {
			${LinkStyle};
			font-weight: ${isActive ? 600 : 400};
			color: ${isActive ? RED : color};
		}
	`,
);
