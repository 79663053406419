import React, { createContext, Dispatch, ReactNode, SetStateAction, useContext, useMemo, useState } from 'react';

const SideBarStateContext = createContext(false);

const SideBarStateDispatchContext = createContext<Dispatch<SetStateAction<boolean>>>(() => undefined);

// SideBar 컴포넌트 open 여부 관리 context
const SideBarProvider = ({ children }: { children: ReactNode }) => {
	const [state, setState] = useState(false);
	const value = useMemo(() => state, [state]);

	return (
		<SideBarStateContext.Provider value={value}>
			<SideBarStateDispatchContext.Provider value={setState}>{children}</SideBarStateDispatchContext.Provider>
		</SideBarStateContext.Provider>
	);
};

export default SideBarProvider;

// SideBar open 여부 state
export const useGetSideBarState = () => {
	const sideBarState = useContext(SideBarStateContext);
	return sideBarState;
};

// SideBar open 여부 제어
export const useSetSideBarState = () => {
	const setSideBarState = useContext(SideBarStateDispatchContext);
	return setSideBarState;
};
