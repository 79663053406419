// 16 -> "1rem"
export const pxToRem = (px: number) => `${px / 16}rem`;

// 2021-01-01 19:23:49 -> 2021년 01월 01일
export const getDateFormat = (date: string) => {
	if (!date) return '-';
	const [year, month, day] = date.slice(0, 10).split('-');
	return `${year}년 ${month}월${day}일`;
};

// 1524693 -> '152KB'
export const formatBytes = (bytes: number) => {
	if (bytes === 0) return '0 Bytes';

	const k = 1000; // 1024보다 보편적 수치?
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
	const i = Math.floor(Math.log(bytes) / Math.log(k));

	return `${parseFloat((bytes / Math.pow(k, i)).toFixed(0))}${sizes[i]}`;
};

// 'https://{domain}/image.jpg' -> 'JPG'
export const getFileType = (contentType: string) => {
	const regex = /(\w|-)+./g;
	const result = contentType.match(regex);
	return result ? result[result.length - 1].toUpperCase() : '';
};

export const isDevelopment = process.env.GATSBY_ENV || process.env.NODE_ENV === 'development';

export const debounce = (func: (...params: any) => void, wait: number) => {
	let timer: NodeJS.Timeout | undefined;

	return (...params: any) => {
		if (timer) {
			clearTimeout(timer);
			timer = undefined;
		}

		timer = setTimeout(() => {
			func(...params);
			timer = undefined;
		}, wait);
	};
};

export const throttle = (func: (...parmas: any) => void, time: number) => {
	let timer: NodeJS.Timeout | undefined;

	return (...params: any) => {
		if (!timer) {
			timer = setTimeout(() => {
				func(...params);
				timer = undefined;
			}, time);
		}
	};
};
