import React, { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from 'react';
import { HeaderMenuType, HeaderStyleType } from '../../data/header';

const HeaderStyleStateContext = createContext<{ activeMenuType: HeaderMenuType; headerStyleType: HeaderStyleType }>({
	activeMenuType: HeaderMenuType.None,
	headerStyleType: HeaderStyleType.White,
});
const HeaderStyleDispatchContext = createContext<
	Dispatch<SetStateAction<{ activeMenuType: HeaderMenuType; headerStyleType: HeaderStyleType }>>
>(() => undefined);

// Header 컴포넌트의 배경색상을 관리하는 context
const HeaderStyleProvider = ({ children }: { children: ReactNode }) => {
	const [state, setState] = useState<{ activeMenuType: HeaderMenuType; headerStyleType: HeaderStyleType }>({
		activeMenuType: HeaderMenuType.Default,
		headerStyleType: HeaderStyleType.White,
	});

	return (
		<HeaderStyleStateContext.Provider value={state}>
			<HeaderStyleDispatchContext.Provider value={setState}>{children}</HeaderStyleDispatchContext.Provider>
		</HeaderStyleStateContext.Provider>
	);
};

export default HeaderStyleProvider;

export const useGetHeaderStyle = () => {
	const headerStyle = useContext(HeaderStyleStateContext);
	return headerStyle;
};

export const useSetHeaderStyle = () => {
	const setHeaderStyle = useContext(HeaderStyleDispatchContext);
	return setHeaderStyle;
};
