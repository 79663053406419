import { css, SerializedStyles } from '@emotion/react';
import { TABLET_BREAKPOINT } from '../constants/layout';
import { pxToRem } from '../utils/common';

export const getDesktopStyle = (style: SerializedStyles) => css`
	@media (min-width: ${pxToRem(TABLET_BREAKPOINT)}) {
		${style}
	}
`;

export const showOnDesktopStyle = css`
	display: none;

	${getDesktopStyle(css`
		display: block;
	`)}
`;

export const showOnMobileStyle = css`
	display: block;

	${getDesktopStyle(css`
		display: none;
	`)}
`;
