export const DesktopFooterData = [
	{
		id: 0,
		info: ['(주) 펫닥', '사업자등록번호 : 432-86-00569', '대표이사 : 오태환', '개인정보관리책임자 : 오태환'],
	},
	{ id: 1, text: '서울특별시 서초구 강남대로79길 32 펫닥' },
	{ id: 2, text: 'Tel : 02.417.3002 월-금 (10:00 - 19:00) Email : cert@petdoc.co.kr' },
	{ id: 3, text: '© 2022 PetDoc Inc. All Rights Reserved.' },
];

export const MobileFooterData = [
	{
		id: 0,
		text: '(주) 펫닥 | 사업자등록번호 : 432-86-00569',
	},
	{ id: 1, text: '대표이사 | 오태환' },
	{ id: 2, text: '개인정보관리책임자 | 오태환' },
	{ id: 3, text: '주소 | 서울특별시 서초구 강남대로79길 32, 펫닥' },
	{ id: 4, text: 'Tel | 02.417.3002 월-금 (10:00 - 19:00)' },
	{ id: 5, text: 'Email : cert@petdoc.co.kr' },
	{ id: 6, text: '© 2022 PetDoc Inc. All Rights Reserved.' },
];
