export const REAL_BLACK = '#000000';
export const BLACK = '#4E5263';
export const RED = '#FF5A5C';
export const DEEPRED = '#A3403C'; // 공고 hover 시
export const WHITE = '#FFFFFF';
export const SKYBLUE = '#65B3DD'; // 쇼핑몰 바로가기
export const DEEPGREEN = '#4E8361'; // SNS 바로가기
export const GRAY_00 = '#C4C4C4';
export const GRAY_01 = '#E5E5E5'; // 섹션 배경
export const GRAY_02 = '#E4E6E9'; // 수의사 카드 배경
export const GRAY_03 = '#C9CDD3'; // 브이케어 아이콘
export const GRAY_04 = '#929292'; // petmily lab mobile 버전 pagenation 점
export const GRAY_05 = '#EFEFEF'; // 진료 과목 background
export const RAINBOW_ORANGE = '#F67E3A';
export const RAINBOW_YELLOW = '#FFD600';
export const RAINBOW_GREEN = '#2EB580';
export const RAINBOW_BLUE = '#2B7CFF';
export const RAINBOW_NAVY = '#0A4B85';
export const RAINBOW_PURPLE = '#6334A3';
